<template>
    <v-container fluid>  
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field
                        placeholder="Procurar"
                        dense
                        solo
                        background-color="#FFFFFF"
                        hide-details
                        v-model="search"            
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn block color="amber darken-4" dark @click="dialogSave()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </TheTitle>
  
        <v-card class="pa-5 full-height">
            <v-data-table
                :headers="header"
                :items="objects"
                dense
                locale="pt-BR"
                :search="search"
                :items-per-page="15"
            >
                <template v-slot:[`item.incidentDate`]="{ item }">
                    {{ item.incidentDate | formatDate }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <TheToolTip label="Comunicar Incidente">
                        <v-btn icon small tile >
                            <v-icon @click="dialogSendEmail(item)">mdi mdi-email-arrow-right-outline</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Visualização">
                        <v-btn icon small tile >
                            <v-icon @click="dialogView1(item)">mdi mdi-file-eye-outline</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Mitigação">
                        <v-btn icon small tile >
                            <v-icon @click="mitigation(item.id)">mdi mdi-notebook-outline</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Titulares Afetados">
                        <v-btn icon small tile >
                            <v-icon @click="affectedHolders(item.id)">mdi mdi-lan</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Editar">
                        <v-btn icon small tile>
                            <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover">
                        <v-btn icon small tile>
                            <v-icon @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>
            </v-data-table>
        </v-card>
  
      <!-- MODAL -->
        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="1200px">
            
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-tabs v-model="tabs" show-arrows>
                        <v-tab>Dados</v-tab>
                        <v-tab>Justificativas</v-tab>
                        <v-tab>Medidas</v-tab>
                        <v-tab>Descrição</v-tab>
                    </v-tabs>
                    <v-divider class="my-5" />
                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <v-row>
                                <v-col md="3" sm="12" cols="12">
                                    <b> Data de conhecimento *</b>
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="object.incidentDate"
                                                persistent-hint
                                                prepend-inner-icon="mdi-calendar"
                                                v-bind="attrs"
                                                @input="date = parseDate(object.incidentDate)"
                                                v-on="on"
                                                hide-details
                                                dense
                                                outlined
                                                class="mt-2"
                                                :rules="[requiredRule, dateBirthRule]"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            :rules="requiredRule"
                                            v-model="date"
                                            no-title
                                            @input="menu1 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>  
                                <v-col md="4" sm="12" cols="12">
                                    <b>Tipo de dados *</b>
                                    <v-select
                                        v-model="object.typeData"
                                        :items="type"
                                        outlined
                                        tile
                                        dense
                                        class="mt-2"
                                        color="primary"
                                        :rules="[requiredRule, spaceRule]"
                                    ></v-select>                    
                                </v-col>  
                                <v-col md="5" sm="12" cols="12">              
                                    <b> Natureza *</b>
                                    <v-text-field
                                        dense
                                        tile
                                        outlined
                                        class="my-2"
                                        :rules="[requiredRule, spaceRule]"
                                        v-model="object.nature"
                                    ></v-text-field>
                                </v-col>     
                            </v-row>
                            
                            <v-row>                        
                                <v-col md="3" sm="12" cols="12">
                                    <b>Tipo de operador *</b>
                                    <v-select
                                        v-model="object.typeOperating"
                                        :items="Operating"
                                        outlined
                                        tile
                                        dense
                                        class="mt-2"
                                        color="primary"
                                        :rules="[requiredRule, spaceRule]"
                                    ></v-select>                    
                                </v-col> 
                                <v-col md="3" sm="12" cols="12">
                                    <b>Número de Titulares * </b>
                                    <v-text-field
                                        :rules="[requiredRule, spaceRule]"
                                        v-model="object.numberHolders"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        v-mask="'###'"
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" sm="12" cols="12">
                                    <b>Número de Titulares Crianças *</b>
                                    <v-text-field
                                        :rules="[requiredRule, spaceRule]"
                                        v-model="object.numberChildHolders"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        v-mask="'###'"
                                    ></v-text-field>
                                </v-col>
                                
                                <v-col md="3" sm="12" cols="12">
                                    <b>Grau de Risco *</b>
                                    <v-select
                                        v-model="object.risk"
                                        :items="risk"
                                        outlined
                                        tile
                                        dense
                                        class="mt-2"
                                        color="primary"
                                        :rules="[requiredRule, spaceRule]"
                                    ></v-select>                    
                                </v-col>                                    
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Justificativa pelo atraso na comunicação </b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="object.justification"
                                        class="my-2"
                                        auto-grow
                                    ></v-textarea>
                                </v-col>          
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Medidas tomadas antes *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="object.measuresBefore"
                                        class="my-2"
                                        auto-grow
                                        :rules="[requiredRule, spaceRule]"
                                    ></v-textarea>
                                </v-col>          
                            </v-row>          
                            <v-divider class="my-2"></v-divider>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Medidas tomadas após *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="object.measuresAfter"
                                        class="my-2"
                                        auto-grow
                                        :rules="[requiredRule, spaceRule]"
                                    ></v-textarea>
                                </v-col>          
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>
                                <v-col cols="12">
                                    <b> Descrição *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="object.description"
                                        class="my-2"
                                        auto-grow
                                        :rules="[requiredRule, spaceRule]"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>
                    </v-tabs-items>                  
                </v-form>
            </template>
            
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"
                    class="mt-2"
                    dark
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>    
        </TheDialog>
        
        <!-- EMAIL -->
        <template>
            <v-dialog v-model="dialogCommunication" max-width="1200px">
                <v-card class="pa-5">
                    <v-card-title>
                        <span class="headline"><v-icon  class="mr-2">mdi mdi-email-arrow-right-outline</v-icon>Enviar Comunicação de Incidente</span>
                    </v-card-title>
                    <v-divider class="my-2"></v-divider>
                    <v-tabs v-model="tabs" show-arrows>
                        <v-tab>Dados</v-tab>
                        <v-tab>Justificativas</v-tab>
                        <v-tab>Medidas</v-tab>
                        <v-tab>Descrição</v-tab>
                    </v-tabs>
                    <v-divider class="my-5" />
                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <v-row>
                                <v-col md="3" sm="12" cols="12">
                                    <b> Data de conhecimento *</b>
                                    <v-text-field
                                        v-model="objectEmail.incidentDate"
                                        hide-details
                                        dense
                                        outlined
                                        class="mt-2"
                                        disabled
                                    ></v-text-field>                                        
                                </v-col>  
                                <v-col md="4" sm="12" cols="12">
                                    <b>Tipo de dados *</b>
                                    <v-text-field
                                        v-model="objectEmail.typeData"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        disabled
                                    ></v-text-field>                    
                                </v-col>  
                                <v-col md="5" sm="12" cols="12">              
                                    <b> Natureza *</b>
                                    <v-text-field
                                        dense
                                        tile
                                        outlined
                                        class="my-2"
                                        v-model="objectEmail.nature"
                                        disabled
                                    ></v-text-field>
                                </v-col>     
                            </v-row>
                            
                            <v-row>                        
                                <v-col md="3" sm="12" cols="12">
                                    <b>Tipo de operador *</b>
                                    <v-text-field
                                        v-model="objectEmail.typeOperating"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        disabled
                                    ></v-text-field>                    
                                </v-col> 
                                <v-col md="3" sm="12" cols="12">
                                    <b>Número de Titulares * </b>
                                    <v-text-field
                                        v-model="objectEmail.numberHolders"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        v-mask="'###'"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" sm="12" cols="12">
                                    <b>Número de Titulares Crianças *</b>
                                    <v-text-field
                                        v-model="objectEmail.numberChildHolders"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        v-mask="'###'"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                
                                <v-col md="3" sm="12" cols="12">
                                    <b>Grau de Risco *</b>
                                    <v-text-field
                                        v-model="objectEmail.risk"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        disabled
                                    ></v-text-field>                    
                                </v-col>                                    
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Justificativa pelo atraso na comunicação </b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectEmail.justification"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>          
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Medidas tomadas antes *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectEmail.measuresBefore"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>          
                            </v-row>          
                            <v-divider class="my-2"></v-divider>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Medidas tomadas após *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectEmail.measuresAfter"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>          
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>
                                <v-col cols="12">
                                    <b> Descrição *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectEmail.description"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>
                    </v-tabs-items>  

                    <v-card-actions>
                        <v-btn color="primary" @click="openEmailDialog()">
                            <v-icon  class="mr-2">mdi mdi-email-arrow-right-outline </v-icon> Enviar E-mail
                        </v-btn>
                        <v-btn color="secondary" @click="closeDialogEmail()">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="emailDialog" max-width="500px">
                <v-card>
                    <v-card-title>Enviar E-mail</v-card-title>
                    <v-card-text>
                    <v-text-field
                        v-model="email"
                        :rules="[requiredRule, validarEmail]"
                        required
                        outlined
                        tile
                        dense
                        class="mt-2"
                    ></v-text-field>
                    </v-card-text>
                        <v-card-actions>
                        <v-btn color="primary" @click="sendEmail()">Enviar</v-btn>                           
                        <v-btn color="secondary" @click="closeEmailDialog()">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <!-- Vizualização -->
        <template>
            <v-dialog v-model="dialogView" max-width="1200px">
                <v-card class="pa-5">
                    <v-card-title>
                        <span class="headline"><v-icon class="mr-2">mdi mdi-shield-alert</v-icon>Detalhes do Incidente</span>
                    </v-card-title>
                    <v-divider class="my-2"></v-divider>
                    <v-tabs v-model="tabs" show-arrows>
                        <v-tab>Dados</v-tab>
                        <v-tab>Justificativas</v-tab>
                        <v-tab>Medidas</v-tab>
                        <v-tab>Descrição</v-tab>
                    </v-tabs>
                    <v-divider class="my-5" />
                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <v-row>
                                <v-col md="3" sm="12" cols="12">
                                    <b> Data de conhecimento *</b>
                                    <v-text-field
                                        v-model="objectView.incidentDate"
                                        hide-details
                                        dense
                                        outlined
                                        class="mt-2"
                                        disabled
                                    ></v-text-field>                                        
                                </v-col>  
                                <v-col md="4" sm="12" cols="12">
                                    <b>Tipo de dados *</b>
                                    <v-text-field
                                        v-model="objectView.typeData"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        disabled
                                    ></v-text-field>                    
                                </v-col>  
                                <v-col md="5" sm="12" cols="12">              
                                    <b> Natureza *</b>
                                    <v-text-field
                                        dense
                                        tile
                                        outlined
                                        class="my-2"
                                        v-model="objectView.nature"
                                        disabled
                                    ></v-text-field>
                                </v-col>     
                            </v-row>
                            
                            <v-row>                        
                                <v-col md="3" sm="12" cols="12">
                                    <b>Tipo de operador *</b>
                                    <v-text-field
                                        v-model="objectView.typeOperating"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        disabled
                                    ></v-text-field>                    
                                </v-col> 
                                <v-col md="3" sm="12" cols="12">
                                    <b>Número de Titulares * </b>
                                    <v-text-field
                                        v-model="objectView.numberHolders"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        v-mask="'###'"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col md="3" sm="12" cols="12">
                                    <b>Número de Titulares Crianças *</b>
                                    <v-text-field
                                        v-model="objectView.numberChildHolders"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        v-mask="'###'"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                
                                <v-col md="3" sm="12" cols="12">
                                    <b>Grau de Risco *</b>
                                    <v-text-field
                                        v-model="objectView.risk"
                                        outlined
                                        tile
                                        dense
                                        class="my-2"
                                        disabled
                                    ></v-text-field>                    
                                </v-col>                                    
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Justificativa pelo atraso na comunicação </b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectView.justification"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>          
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Medidas tomadas antes *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectView.measuresBefore"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>          
                            </v-row>          
                            <v-divider class="my-2"></v-divider>
                            <v-row>   
                                <v-col cols="12">
                                    <b> Medidas tomadas após *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectView.measuresAfter"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>          
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row>
                                <v-col cols="12">
                                    <b> Descrição *</b>
                                    <v-textarea
                                        outlined
                                        rows="5"
                                        v-model="objectView.description"
                                        class="my-2"
                                        auto-grow
                                        disabled
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </v-tab-item>
                    </v-tabs-items>  

                    <v-card-actions>
                        <v-btn color="primary" @click="closeDialog()">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-container>
</template>
  
<script>
    import TheTitle from "@/components/TheTitle"
    import TheDialog from "@/components/TheDialog"
    import TheToolTip from "@/components/TheToolTip"
    import { mask } from "vue-the-mask"
    import { baseApiUrl, showError } from "@/global"
    import axios from "axios"
    import moment from 'moment'
  
    export default {
    
    name: "Incidentes",
    directives: { mask },
    components: {
        TheTitle,
        TheDialog,
        TheToolTip,
    },
    
    data: () => ({
        baseRoute: "incidentsNPD",
        title: "Incidentes NPD",
        pageIcon: "mdi mdi-shield-alert",

        dialogView: false,

        email: '',
        emailDialog: false,
        dialogCommunication: false,

        menu1: false,
        date: null,
        tabs: null,
    
        object: {},
        objects: [],
        objectView: {},
        objectEmail: {},

        dialog: false,
        dialogEmail: false,
        search: null,
        valid: false,
        error: false,
        edit: false,
        loading: false,
    
        header: [
            {text: 'Codigo', value: 'id', width: '10%'},
            {text: 'Tipo de Dados', value: 'typeData'},
            {text: 'Natureza', value: 'nature'},
            {text: 'Tipo de Operador', value: 'typeOperating'},
            {text: 'Grau de Risco', value: 'risk'},
            {text: 'Data do incidente', value: 'incidentDate'},
            { text: "Ações", value: "action"},
        ],    
        type: [
            {text: 'Dados Sensíveis', value: 'Dados Sensíveis'},
            {text: 'Crianças, adolecentes ou idosos', value: 'Crianças, adolecentes ou idosos'},
            {text: 'Financeiros', value: 'Financeiros'},
            {text: 'Autenticação em sistemas', value: 'Autenticação em sistemas'},
            {text: 'Larga escala', value: 'Larga escala'},
        ],    
        Operating: [
            {text: 'Próprio', value: 'Próprio'},
            {text: 'Terceiro', value: 'Terceiro'},
        ],    
        risk: [
            {text: 'Baixo', value: 'Baixo'},
            {text: 'Médio', value: 'Médio'},
            {text: 'Alto', value: 'Alto'},
        ],    
    }),

    watch: {
        date() {
            this.object.incidentDate = this.date
            this.object.incidentDate = moment(this.object.incidentDate).format('DD/MM/YYYY')
        },
    },

    methods: {        

        loadData() {
            this.$store.dispatch("setLoadingInfo", true)
            const url = `${baseApiUrl}/${this.baseRoute}`
            axios
                .get(url)
                .then((res) => {
                    this.$store.dispatch("setLoadingInfo", false)
                    this.objects = res.data
                })
                .catch(showError)
        },

        dialogSave() {
            this.object = {}
            this.edit = false
            this.dialog = true
        },
        
        openEmailDialog () {
            this.emailDialog = true
        },

        closeEmailDialog () {
            this.emailDialog = false
        },

        sendEmail () {
            if (this.email === '') {
                return this.resolveForm()
            }

            const url = `${baseApiUrl}/communication/sendEmail/email`
            
            this.objectEmail.email = this.email
            this.objectEmail.idIncident = this.objectEmail.id
            
            const objects = this.objectEmail
            
            axios
                .post(url, objects)
                .then(() =>
                    this.$store.dispatch('setErrorInfo', {
                        open: true,
                        text: 'E-mail eviado com sucesso!',
                    }),
                    this.loadData()
                )
                .catch(showError)
                .finally(() => {
                    this.dialogCommunication = false
                })

            this.closeEmailDialog()
        },

        dialogSendEmail(item) {
            this.objectEmail = item;
            this.objectEmail.incidentDate = moment(new Date(this.objectEmail.incidentDate)).format('DD/MM/YYYY');
            this.dialogCommunication = true;
        },

        closeDialogEmail() {
            this.objectView = {}
            this.dialogCommunication = false
            this.loadData()                
        },

        dialogUpdate(item) {
            this.object = item
            this.object.incidentDate = moment(new Date(this.object.incidentDate)).format('DD/MM/YYYY')
            this.edit = true
            this.dialog = true
        },      

        dialogView1(item) {
            this.objectView = item;
            this.objectView.incidentDate = moment(new Date(this.objectView.incidentDate)).format('DD/MM/YYYY');
            this.dialogView = true;
        },

        closeDialog() {
            this.objectView = {}
            this.dialogView = false
            this.loadData()                
        },

        resolveForm(){
            if (!this.valid){
                this.$store.dispatch("setErrorInfo", {
                    open: true,
                    text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                    button: "Ok!",
                })
                return
            }
            if(this.edit){
                this.update()
            } else {
                this.save()
            }
        },

        save(){
            this.error = false
            const url = `${baseApiUrl}/${this.baseRoute}`
            const objects = this.object
            objects.incidentDate = this.date
            axios
                .post(url, objects)
                .then(() => this.loadData())
                .catch(showError)
                .finally(() => {
                    this.dialog = false
                })  
        },

        update() {
            this.error = false
            const id = this.object.id
            const url = `${baseApiUrl}/${this.baseRoute}/${id}`
            const objects = this.object

            if(this.date){
                objects.incidentDate = this.date
            } else {
                const data = this.object.incidentDate.split('/')
                const parseDate = `${data[2]}-${data[1]}-${data[0]}` 

                objects.incidentDate = parseDate
            }

            delete objects.id

            delete objects.createdAt
            delete objects.updatedAt
            delete objects.deletedAt
            axios
                .put(url, objects)
                .then(() => this.loadData())
                .catch(showError)
                .finally(() => {
                    this.dialog = false
                })
        },

        remove(id) {
            if (confirm("Remove?")) {
                const url = `${baseApiUrl}/${this.baseRoute}/${id}`
                axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
            }
        },

        affectedHolders(id){
            const route = {
                name: 'Titulares Afetados',
                params: { id: id }
            }
            this.$router.push(route)
        },
        
        mitigation(id){
            const route = {
                name: 'Mitigation',
                params: { id: id }
            }
            this.$router.push(route)
        },

        parseDate(date) {
            if (!date) return null
            const [month, day, year] = date.substring(0, 11).split("/")
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        },
    },

    mounted() {
        this.loadData()
    },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>